.PartitionedBooksModal-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: white;
  z-index: 3;
  overflow: auto;
  padding: 16px 12px;
}

.PartitionedBooksModal-books-container {
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
}

.PartitionedBooksModal-books-container-item {
  width: calc(100% / 2);
  padding: 8px;
}

@media all and (min-width: 768px) {
  .PartitionedBooksModal-books-container-item {
    width: calc(100% / 4);
  }
}

@media all and (min-width: 992px) {
  .PartitionedBooksModal-books-container-item {
    width: calc(100% / 6);
  }
}

@media all and (min-width: 1200px) {
  .PartitionedBooksModal-books-container-item {
    width: calc(100% / 8);
  }
}

.PartitionedBooksModal-container-close-button {
  display: flex;
  position: absolute;
  top: 16px;
  right: 16px;
}

.PartitionedBooksModal-container-close-icon {
  width: 32px;
  height: 32px;
}
