@import-normalize;

:root {
  --color-primary: #f53b57;
  --color-secondary: #78e08f;
  --color-disabled: #bdc3c7;
}

html {
  /* Prevent padding and border from affecting element width */
  box-sizing: border-box;
  min-height: 100%;
  line-height: 1.5;

  /* Breaks words to prevent overflow in all browsers */
  overflow-wrap: break-word;

  /* Remove the grey highlight on links in iOS */
  -webkit-tap-highlight-color: transparent;

  /* Prevent adjustments of font size after orientation changes in iOS. */
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  display: flex;
  flex-flow: column nowrap;
  min-height: 100%;
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  background-color: var(--color-primary);
}

#root {
  display: flex;
  flex: 1;
  flex-flow: column nowrap;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

img {
  display: block;
}

img:-moz-loading {
  visibility: hidden;
}

a {
  font-weight: 600;
  color: var(--color-primary);
  text-decoration: inherit;
}

button {
  padding: 0;
  line-height: inherit;
  color: inherit;
  cursor: pointer;
  background-color: transparent;
  border: 0;
}

button:focus {
  outline: 0;
}
