.App {
  position: relative;
}

.App-header {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  color: white;
}

.App-input {
  width: 90%;
  padding: 16px 12px;
  font-size: 18px;
  font-weight: 500;
  color: white;
  background: rgba(0, 0, 0, 0.15);
  border: none;
  border-radius: 2px;
  -webkit-appearance: none;
}

.App-input:focus {
  outline: none;
}

.App-container {
  display: flex;
  position: relative;
  flex-flow: row wrap;
  align-items: flex-start;
  padding: 12px 16px;
  z-index: 1;
}

.App-container-item {
  width: calc(100% / 2);
  padding: 8px;
}

@media all and (min-width: 768px) {
  .App-container-item {
    width: calc(100% / 4);
  }
}

@media all and (min-width: 992px) {
  .App-container-item {
    width: calc(100% / 6);
  }
}

@media all and (min-width: 1200px) {
  .App-container-item {
    width: calc(100% / 8);
  }
}

.App-action-buttons {
  position: fixed;
  bottom: 8px;
  right: 8px;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  z-index: 2;
}

.App-action-button {
  display: flex;
  background: white;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1), 0 3px 6px rgba(0, 0, 0, 0.2);
  border-radius: 50%;
}

.App-action-button:disabled {
  cursor: not-allowed;
}

.App-action-button-icon {
  fill: var(--color-primary);
}

.App-action-button:disabled .App-action-button-icon {
  fill: var(--color-disabled);
}

.App-action-button-refresh {
  padding: 8px;
  margin-bottom: 8px;
}

.App-action-button-buy {
  padding: 12px;
}

.App-action-button-refresh-icon {
  width: 24px;
  height: 24px;
}

.App-action-button-force-refresh-icon {
  fill: var(--color-secondary);
}

.App-action-button-buy-icon {
  width: 32px;
  height: 32px;
}
