.BookCard-container {
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  overflow: hidden;
  background: white;
  border-radius: 4px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1), 0 3px 6px rgba(0, 0, 0, 0.2);
}

.BookCard-thumbnail-aspect-ratio {
  position: relative;
  width: 100%;
  height: 0;
  overflow: hidden;
  cursor: pointer;
  user-select: none;
}

.BookCard-thumbnail-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 2;
}

.BookCard-thumbnail-overlay-selected {
  background: white;
  opacity: 0.5;
}

.BookCard-thumbnail-delete {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 3;
  background: black;
  margin-top: 4px;
  margin-right: 4px;
  padding: 4px 8px;
  border-radius: 4px;
  color: white;
  font-weight: 600;
  font-size: 12px;
}

.BookCard-thumbnail-price-tag {
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 4;
  background: var(--color-secondary);
  color: black;
  font-weight: 600;
  font-size: 12px;
  padding: 4px 12px;
  border-top-left-radius: 4px;
}

.BookCard-thumbnail {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.BookCard-sources {
  flex: 1;
  padding: 8px 12px;
  font-size: 12px;
}

.BookCard-source {
  float: left;
}

.BookCard-source-oudated {
  color: var(--color-disabled);
}

.BookCard-source-selected {
  color: black;
}

.BookCard-source-separator {
  float: left;
  margin: 0 4px;
}

.BookCard-source-price {
  margin-left: 4px;
}
